
import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin,faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import ArrowDown from './utils/ArrowDown'

export default function Footer() {
    const [currentPage, SetCurrentPage] = useState(window.location.pathname);

    useEffect(() => {
        SetCurrentPage(window.location.pathname);
    }, [])

    return (
        <footer>
            {currentPage === '/apropos' ? '' : <ArrowDown height={160} />}
            <div className='inner-footer' id="contact">
                <div className='contact-section'>
                    <div className='kasavubu first-div'>
                        <h3 className='footer-title'>Galien Kasa-Vubu</h3>
                        <ul>
                            <li>
                                <span className='footer-icon'><FontAwesomeIcon icon={faLocationDot} /></span>
                                <span><a href='https://maps.app.goo.gl/QFmZFqa2Vq5UFHxZ8' target='_blank' rel="noreferrer">1841, Av. Kasa-Vubu<br />
                                    Lubumbashi, R. D. Congo</a></span>
                            </li>
                            <li>
                                <span className='footer-icon'><FontAwesomeIcon icon={faPhone} /></span>
                                <span><a href="tel:+243997130595">+243 997 130 595</a><br />
                                    <a href="tel:+243818150440">+243 818 150 440</a></span>
                            </li>
                            <li>
                                <span className='footer-icon'><FontAwesomeIcon icon={faEnvelope} /></span>
                                <span><a href="mailto:poligalien@yahoo.fr">poligalien@yahoo.fr</a></span>

                            </li>
                        </ul>
                    </div>
                    <div className='biayi'>
                        <h3 className='footer-title'>Extension Biayi</h3>
                        <ul>
                            <li>
                                <span className='footer-icon'><FontAwesomeIcon icon={faLocationDot} /></span>
                                <span><a href='https://maps.app.goo.gl/kdCD2ZMSpLq2NSpz6' target='_blank' rel="noreferrer">34, Av. Biayi<br />
                                    Lubumbashi, R. D. Congo</a></span>
                            </li>
                            <li>
                                <span className='footer-icon'><FontAwesomeIcon icon={faPhone} /></span>
                                <span><a href="tel:+243975115056">+243 975 115 056</a></span>
                            </li>
                            <li>
                                <span className='footer-icon'><FontAwesomeIcon icon={faEnvelope} /></span>
                                <span><a href="mailto:poligalien@yahoo.fr">poligalien@yahoo.fr</a></span>

                            </li>
                        </ul>
                    </div>
                    <div className='social'>
                        <h3 className='footer-title'>Réseaux sociaux</h3>
                        <ul>
                            <li>
                                <a href='https://www.linkedin.com/company/poligalien/about/' target='_blank' rel="noreferrer">
                                    <span className='footer-icon'>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </span>
                                    <span>LinkedIn</span>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.facebook.com/poligalien' target='_blank' rel="noreferrer">
                                    <span className='footer-icon'>
                                        <FontAwesomeIcon icon={faFacebookSquare} />
                                    </span>
                                    <span>Facebook</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='bottom-footer'>
                <span className='copyright'>&copy;2024 Policlinique Galien. Tous droits réservés, powered by <a href="https://www.yannickmakwenge.com/" target="_blank" rel="noreferrer">YBM</a></span>
            </div>
        </footer>
    )
}

