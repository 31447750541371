import { React, useState, useEffect } from 'react'
import logo from '../images/logo-couleur.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

export default function Navbar() {
    const [currentPage, SetCurrentPage] = useState(window.location.pathname);

    useEffect(() => {
        SetCurrentPage(window.location.pathname);
    }, [])

    const handleMouseOverServices = () => {
        document.getElementById('dropdown-menu-services').classList.add('active');
    }
    
    const handleMouseOverAboutUs = () => {
        document.getElementById('dropdown-menu-apropos').classList.add('active');
    }

    return (
        <nav className='nav'>
            <div className='top-nav'>
                <div className='container'>
                    <div>
                        <span className='icon'><FontAwesomeIcon icon={faLocationDot} /></span>
                        <span>République Démocratique du Congo</span>
                    </div>
                    <div>
                        <a href="#contact">Contactez-nous</a>
                    </div>
                </div>
            </div>
            <div className='bottom-nav'>
                <div className='container'>
                    <a href='/' className='logo'>
                        <img src={logo} alt="Galien logo" />
                    </a>
                    <div className='menu'>
                        <ul>
                            <li><a href='/' className={currentPage === '/' ? 'current-page' : ''}>ACCUEIL</a></li>
                            <li><a href='/services' className={currentPage === '/services' ? 'current-page' : ''} onMouseOver={handleMouseOverServices}>NOS SERVICES MÉDICAUX ▼</a></li>
                            <li><a href='/medecine-travail' className={currentPage === '/medecine-travail' ? 'current-page' : ''}>MÉDECINE DU TRAVAIL</a></li>
                            <li><a href='/apropos' className={currentPage === '/apropos' ? 'current-page' : ''} onMouseOver={handleMouseOverAboutUs}>À PROPOS ▼</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}