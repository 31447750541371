import React, { useState } from 'react'
import CountUp from 'react-countup';
import ScrollTirgger from 'react-scroll-trigger';

export default function FactsCTA() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTirgger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
      <section className='fact-section'>
        <div className='container'>

          <div className='fact'>
            <div className='fact-number'>
              <span className='num'>
                {counterOn && <CountUp start={0} end={35} duration={2} delay={0}/>}
              </span>
              <span className='plussign'>
                +
              </span>
            </div>
            <h1>
              Années d'éxistance
            </h1>
          </div>
          <div className='fact'>
            <div className='fact-number'>
              <span className='num'>
                {counterOn && <CountUp start={0} end={10000} duration={3} delay={0}/>}
              </span>
              <span className='plussign'>
                +
              </span>
            </div>
            <h1>
              Consultations par an
            </h1>
          </div>
          <div className='fact'>
            <div className='fact-number'>
              <span className='num'>
               {counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}
              </span> 
              <span className='plussign'>
                +
              </span>
            </div>
            <h1>
              Abonnés
            </h1>
          </div>
          <div className='fact'>
            <div className='fact-number'>
              <span className='num'>
                {counterOn && <CountUp start={0} end={50} duration={2} delay={0} />}
              </span>
              <span className='plussign'>
                +
              </span>
            </div>
            <h1>
              Partenaires
            </h1>
          </div>
        </div>
      </section>
    </ScrollTirgger>
  )
}
