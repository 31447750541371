import React, { useState } from 'react'
import ArrowDown from './utils/ArrowDown';
import model from '../images/system-model.png'
import modelLanscaped from '../images/landscaped.png'
import report from '../images/018-report.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import useWindowSize from './utils/useWindowSize';
import checkDevice from './utils/checkDevice';

export default function MedecineTravail() {
  const [fullScreen, setFullScreen] = useState(false);
  const size = useWindowSize();
  const isMobile = checkDevice(size, 1000)
  const handleFullscreen = (e) => {
    e.preventDefault();
    if (fullScreen) {
      setFullScreen(false)
    } else {
      setFullScreen(true)
    }

  }
  return (
    <div className='medecine'>
      {fullScreen ? <button onClick={handleFullscreen} id='close-model' ><FontAwesomeIcon icon={faXmark} /></button> : ''}
      <section className='section1'>
        <div className='container'>
          <div className='content'>
            <h2>La Médicine du Travail : l'expertise de la Policlinique Galien. </h2>
            <p>Dans le domaine de la médecine du travail, nous nous consacrons à la prévention, au diagnostic et au traitement des maladies et blessures liées aux conditions professionnelles. Nous nous engageons à promouvoir et maintenir le bien-être physique, mental et social des travailleurs.</p>
            <a href="#contact">INSCRIVEZ VOTRE ENTREPRISE CHEZ NOUS
              ET PROTEGEZ VOS TRAVAILLEURS</a>
          </div>
          {/* TODO:find a way to use dvh for height withut damaging picture */}
          <div className='picture'>
            <div className='redline'></div>
            {/* TODO:use background instead of img to change img position*/}
            <div className='img' /></div>
        </div>
      </section>
      <ArrowDown height={160} />
      <section className="section2">
        <h2>Pourquoi s’incrire à notre programme de soins? </h2>
        <div className='cards-container'>
          <div className='card' data-aos="slide-right">
            <img src={report} alt='report'/>
            <h3 className='left-title'>LES AVANTAGES SANTÉ</h3>
            <span className='line'></span>
            <ul className='left-card'>
              <li>Consultations</li>
              <li>Évaluation et Prévention des Risques Professionnels</li>
              <li>Formation et Sensibilisation</li>
              <li>Surveillance Médicale</li>
              <li>Réinsertion et Réadaptation Professionnelle</li>
            </ul>
          </div>
          <div className='card' data-aos="slide-left">
            <h3 className='right-title'>LES POINTS FORTS</h3>
            <span className='line'></span>
            <ul className='right-card'>
              <li>Partenaire avec la plupart d’institutions hospitalières à Lubumbashi,
                Likasi, Kolwezi et Kinshasa (un véritable "HUB MEDICAL")</li>
              <li>Un comité de gestion solide et composé d’expert en santé tel
                que le Dr MAKWENGE Victor</li>
              <li>Plus de 35 ans d’existence</li>
              <li>Deux sites actifs sur Lubumbashi</li>
              <li>Un Service 24h/24 7j/7 avec médecin de garde</li>
              <li>Possède un centre permanent de petites urgences, une pharmacie, un laboratoire et une imagerie médicale essentielle</li>
            </ul>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div className='container'>
          {!fullScreen ? <img id="model" src={model} alt='system-model' onClick={handleFullscreen} /> : <img id="model" src={isMobile ? modelLanscaped : model} alt='system-model' className="full" />}
        </div>   
      </section>
      <section className="section4">
        <h2>Comment s’incrire à notre programme de soins? </h2>
        <div className='cards-container'>
          <div className='card' data-aos="fade-up">
            <div className='num-cont'>
              <span className='num'><strong>01</strong></span>
            </div>
            <div className='text-cont'>
              <p>
                <strong>Prise de Contact Initiale.</strong> Vous nous contactez pour discuter
                de l’adhésion de votre entreprise à notre programme
                de soins.
              </p>
            </div>
          </div>
          <div className='card' data-aos="fade-up">
            <div className='num-cont'>
              <span className='num'><strong>02</strong></span>
            </div>
            <div className='text-cont'>
              <p>
                <strong>Évaluation des Besoins. </strong> Nous organisons une consultation périodique
                avec vos représentants (RH, Syndicats,...) pour évaluer les besoins spécifiques
                de votre entreprise en matière de soins médicaux et conseils.
              </p>
            </div>
          </div>
          <div className='card' data-aos="fade-up">
            <div className='num-cont'>
              <span className='num'><strong>03</strong></span>
            </div>
            <div className='text-cont'>
              <p>
                <strong>Élaboration de la Convention sur Mesure.</strong> En fonction des
                besoins évalués, nous rédigeons une convention médicale
                personnalisée. Elle détaille les services spécifiques, les modalités
                de soins, et les conditions particulières convenues.
              </p>            </div>
          </div>
          <div className='card' data-aos="fade-up">
            <div className='num-cont'>
              <span className='num'><strong>04</strong></span>
            </div>
            <div className='text-cont'>
              <p>
                <strong>Signature de la Convention.</strong> Les représentants de votre
                entreprise et notre équipe signent la convention sur mesure.
                Vous fournissez une liste détaillée de votre personnel
                et de leurs ayants-droits.
              </p>            </div>
          </div>
          <div className='card' data-aos="fade-up">
            <div className='num-cont'>
              <span className='num'><strong>05</strong></span>
            </div>
            <div className='text-cont'>
              <p>
                <strong>Facturation.</strong> Les paiements sont mensuels pour les abonnés et effectués soit
                par acte, soit par forfait, selon le mode négocié entre
                votre entreprise et notre centre, ou encore par paiement cash (carte bancaire, paiements électroniques,...).
              </p>
            </div>
          </div>
        </div>
      </section>
      <ArrowDown height={160} />
    </div>
  )
}
