import React, { useEffect } from 'react'
import ArrowDown from './utils/ArrowDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import dentist1 from '../images/galien-2.jpg'
import dentist2 from '../images/galien-137.jpg'
import ophto from '../images/galien-13.jpg'
import office from '../images/galien-27.jpg'
import useWindowSize from './utils/useWindowSize';
import checkDevice from './utils/checkDevice';
import stethoscope from '../images/002-stethoscope.png'
import medicalKit from '../images/013-medical kit.png'
import tooth from '../images/020-tooth.png'
import bed from '../images/023-bed.png'
import eyeball from '../images/027-eyeball.png'
import walker from '../images/030-walker.png'
import scalpel from '../images/036-scalpel.png'
import heart from '../images/037-heart.png'
import orl from '../images/052-orl.png'
import gyneco from '../images/053-gyneco.png'
import pediaterie from '../images/051-pediatrie.png'

export default function Services(props) {

  useEffect(() => {
    const url = window.location.href.split("services", -1)
    if (url[1] !== "") {
      window.location.href = url[1];
    }
  }, [])

  const size = useWindowSize();
  const isMobile = checkDevice(size, 800)
  return (
    <div className='services'>
      <section className='section1'>
        <div className='redline'></div>
        <div className='container'>
          <h1>
            Nos Services Médicaux
          </h1>
          <p>
            Nous nous distinguons par notre multidisciplinarité et notre rôle de hub médical à Lubumbashi, ainsi que par la diversité et la qualité de nos prestations de services médicaux. Nous offrons un ensemble exhaustif de soins à travers des disciplines classiques et spécialisées, répondant ainsi aux besoins variés des patients de son environnement.
          </p>
          <a href="#contact">Contactez-nous</a>
        </div>
      </section>
      <ArrowDown height={160} />
      <section className='section2' id='classiques'>
        <h2>Disciplines Classiques</h2>
        <span className='line'></span>
        <div className='cards-container '>
          <div className='card' data-aos="zoom-in">
            <img src={stethoscope} alt='medecine-general' />
            <h3>MÉDECINE GÉNÉRALE</h3>
            <ul>
              <li>Consultations pour adultes</li>
              <li>Suivi de maladies chroniques
                (diabète, hypertension, etc.)</li>
              <li>Soins préventifs et curatifs</li>
              <li>Gestion des petites urgences médicales et de triage.</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={pediaterie} alt='pediaterie' />
            <h3>PÉDIATRIE</h3>
            <ul>
              <li>Consultations pédiatriques</li>
              <li>Vaccinations</li>
              <li>Suivi de la croissance et du
                développement de l’enfant</li>
              <li>Traitement des maladies infantiles</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={gyneco} alt='gynecologie' />
            <h3>GYNÉCOLOGIE-OBSTÉTRIQUE</h3>
            <ul>
              <li>Suivi de grossesse et soins prénataux</li>
              <li>Accouchements</li>
              <li>Consultations gynécologiques</li>
              <li>Dépistage et traitement des
                maladies gynécologiques</li>
              <li>Contraception et planning familial</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={scalpel} alt='chirurgie' />
            <h3>CHIRURGIE</h3>
            <ul>
              <li>Chirurgie générale (appendicectomie, herniorraphie, etc.)</li>
              <li>Chirurgie orthopédique (fractures,
                prothèses articulaires)</li>
              <li>Chirurgie digestive</li>
              <li>Chirurgie urologique</li>
              <li>Soins post-opératoires
                et réhabilitation</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={bed} alt='bed' />
            <h3>MÉDECINE INTERNE</h3>
            <ul>
              <li>Diagnostic et traitement des
                maladies internes</li>
              <li>Suivi des patients hospitalisés</li>
              <li>Gestion des maladies métaboliques
                et auto-immunes</li>
              <li>Coordination des soins
                multidisciplinaires</li>
            </ul>
          </div>
        </div>
      </section>
      <section className='section3' id='specialise'>
        <h2>Disciplines Spécialisées</h2>
        <span className='line'></span>
        <div className='cards-container'>
          <div className='card' data-aos="zoom-in">
            <img src={orl} alt='orl' />
            <h3>OTO-RHINO-LARYNGOLOGIE
              (ORL)</h3>
            <ul>
              <li>Consultations ORL</li>
              <li>Diagnostic et traitement des
                affections (oreille, nez et gorge)</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={eyeball} alt='eyeball' />
            <h3>OPHTALMOLOGIE</h3>
            <ul>
              <li>Consultations ophtalmologiques</li>
              <li>Dépistage et traitement des maladies
                oculaires (glaucome, cataracte)</li>
              <li>Examen de la vue et prescription
                de lunettes</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={tooth} alt='tooth' />
            <h3>DENTISTERIE</h3>            <ul>
              <li>Soins dentaires généraux (caries,
                extractions)</li>
              <li>Soins des gencives et parodontologie</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={walker} alt='walker' />
            <h3>KINÉSITHÉRAPIE</h3>
            <ul>
              <li>Rééducation fonctionnelle</li>
              <li>Traitement des douleurs
                musculo-squelettiques</li>
              <li>Réhabilitation post-chirurgicale</li>
              <li>Thérapies manuelles et exercices
                thérapeutiques</li>
            </ul>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={medicalKit} alt='medical kit' />
            <h3><span className='star'><FontAwesomeIcon icon={faStar} /></span>MÉDECINE DU TRAVAIL</h3>
            <ul>
              <li>Consultations de médecine du travail</li>
              <li>Évaluation de la santé des travailleurs</li>
              <li>Prévention des risques professionnels</li>
              <li>Formation et sensibilisation à la santé
                et sécurité au travail</li>
            </ul>
            <a href='/medecine-travail' className='med-trav-link'>En savoir plus</a>
          </div>
          <div className='card' data-aos="zoom-in">
            <img src={heart} alt='heart' />
            <h3>CARDIOLOGIE</h3>
            <ul>
              <li>Consultations et suivie des malades cardio vasculaire</li>
              <li>Echographie cardiaque (ECHO DOPPLER)</li>
              <li>Monitoring</li>
            </ul>
          </div>
        </div>
      </section>
      {!isMobile ?
        <section className='section4'>
          <h2>. . . .</h2>
          <div className='container'>
            <div className='grid1 grid'></div>
            <div className='grid2 grid'></div>
            <div className='grid3 grid'></div>
            <div className='grid4 grid'></div>
          </div>
        </section>
        : <section className='mobile-section4'>
          <Swiper
            className='container'
            data-aos="flip-up"
            modules={[Pagination, Autoplay]}
            spaceBetween={1}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            //onSwiper={(swiper) => console.log(swiper)}
            //onSlideChange={() => console.log('slide change')}
            style={{
              "--swiper-pagination-color": "#f71111",
              "--swiper-pagination-bullet-inactive-color": "#9999",
              "--swiper-pagination-bullet-inactive-opacity": "0.5",
              "--swiper-pagination-bullet-size": "12px",
              "--swiper-pagination-bullet-horizontal-gap": "10px"
            }}
          >
            <SwiperSlide><img src={dentist1} alt="service pic" /></SwiperSlide>
            <SwiperSlide><img src={dentist2} alt="service pic" /></SwiperSlide>
            <SwiperSlide><img src={ophto} alt="service pic" /></SwiperSlide>
            <SwiperSlide><img src={office} alt="service pic" /></SwiperSlide>
          </Swiper >
        </section >}
    </div >
  )
}
