import React from 'react'
import galienFront from '../images/galien-163.jpg'
import photoia from '../images/photo-ia.jpg'
import receptionGalien from '../images/galien-121.jpg'
import laboGalien from '../images/galien-72.jpg'

export default function Apropos() {
  return (
    <div className='apropos'>
      <div className='section1'>
        <div className='container'>
          <p>
            Le terme «Policlinique» dérive du grec ancien πόλις (pólis), signifiant «cité» ou «ville». La clinique se
            positionne comme un «hôpital de jour» visant à minimiser les hospitalisations. Le nom «Galien» honore
            Claude Galien, médecin grec antique et père de la médecine occidentale.
          </p>
        </div>
      </div>
      <div className='section2' id="sommesnous">
        <div className='container1'>
          <div className='wrapper'>
            <div className='img-cont'>
              <img src={galienFront} alt='galien front' />
            </div>
            <div className='text-cont'>
              <h2>Qui sommes-nous?</h2>
              <span className='line'></span>
              <p>La Policlinique Galien, fondée en 1988 à Lubumbashi, est une plateforme médicale gérée par l’association
                sans but lucratif «Les Amis de Galien» (AMIGA asbl). Dédiée à offrir des soins de qualité
                accessibles, elle se concentre principalement sur la médecine préventive, curative et promotionnelle,
                avec une spécialisation marquée en médecine du travail et environnementale, également
                connue sous le nom de médecine occupationnelle.</p>
            </div>
          </div>
        </div>
        <div className='container2' id='valeurs'>
          <h2>Nos valeurs clefs</h2>
          <span className='line'></span>
          <div className='wrapper'>
            <div className='img-cont'>
              <img src={photoia} alt='galien ia' height={50} width={80} />
            </div>
            <div className='text-cont'>
              <ul>
                <li>Conscience Professionnelle</li>
                <li>Humanisation des Soins</li>
                <li>Serviabilité</li>
                <li>Être Proche du Malade</li>
                <li>Professionnalisme</li>
              </ul>
            </div>
          </div>
          <div className='bottom-text'>
            <p>Etant sous gestion de l’Association sans but lucratif «Les
              Amis de Galien» (AMIGA asbl), la Policlinique Galien accorde
              une importance primordiale au <strong>volet humanitaire </strong>
              et <strong>social</strong> de ses actions et activités.</p>
          </div>
        </div>
        <div className='container3' id='mission'>
          <div className='wrapper'>
            <div className='img-cont'>
              <img src={receptionGalien} alt='galien reception' />
            </div>
            <div className='text-cont'>
              <h2>Notre mission</h2>
              <span className='line'></span>
              <p>Animée par une politique de promotion du bien-être physique, mental et social, la Policlinique
                Galien s’est donnée pour mission <strong>d’offrir des soins d’exception à ses patients</strong>. Nous nous engageons
                tout particulièrement à <strong>soutenir ceux qui travaillent dans des conditions professionnelles
                  exigeantes</strong>, en leur assurant une prise en charge adaptée et bienveillante, afin de préserver l'impact négatif sur leur
                santé et booster leur épanouissement au quotidien.</p>
            </div>
          </div>
        </div>
        <div className='grey-line'></div>
        <div className='container4 last' id='vision'>
          <div className='wrapper'>
            <div className='img-cont'>
              <img src={laboGalien} alt='galien labo' />
            </div>
            <div className='text-cont'>
              <h2>Notre vision</h2>
              <span className='line'></span>
              <p>La Policlinique Galien aspire à se développer et à s’agrandir, visant à <strong>devenir un pilier incontournable
                des « hôpitaux de jour » dans le paysage médical</strong> du Haut-Katanga en République Démocratique du Congo.
                Avec une vision tournée vers l’avenir, nous cherchons à redéfinir les standards de soins les meilleurs,
                offrant une expertise médicale de pointe et un service d’excellence qui répondent aux besoins
                évolutifs de notre communauté.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
