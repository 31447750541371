import React from 'react'
import FactsCTA from './FactsCTA';
import homePicture from '../images/galien-120.jpg';
import homePicture1 from '../images/galien-163.jpg';
import homePicture2 from '../images/galien-214.jpg';
import homePicture3 from '../images/galien-223.jpg';
import bagIcon from '../images/acc_vec_02.png'
import ecographieIcon from '../images/acc_vec_03.png'
import labIcon from '../images/acc_vec_04.png'
import stethoscopeIcon from '../images/acc_vec_01.png'
import hospitalisation from '../images/galien-186.jpg'
import hospitalIcon from '../images/acc_vec_05.png'
import consultationPicture from '../images/stethoscope.png'
import ArrowDown from './utils/ArrowDown';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

export default function Accueil() {
  return (
    <div className='accueil'>
      <section className='section1'>
        <div className='container'>
          <div className='content'>
            <h2>Bienvenue à la Policlinique Galien !</h2>
            <p>Depuis plus de 30 ans, nous nous engageons à offrir des soins de qualité et assurons les disciplines classiques de la médecine préventive, curative et promotionnelle. Faites confiance à notre expertise pour un bien-être optimal.</p>
            <a href="#contact">Contactez-nous</a>
          </div>
          {/* TODO:find a way to use dvh for height without damaging picture */}
          <Swiper
            className='picture'
            modules={[Pagination, Autoplay]}
            spaceBetween={1}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            //onSwiper={(swiper) => console.log(swiper)}
            //onSlideChange={() => console.log('slide change')}
            style={{
              "--swiper-pagination-color": "#f71111",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "0.5",
              "--swiper-pagination-bullet-size": "10px",
              "--swiper-pagination-bullet-horizontal-gap": "10px"
            }}
          >
            <div className='redline'></div>
            <SwiperSlide>
              <img src={homePicture} alt='home 1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={homePicture1} alt='home 2' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={homePicture2} alt='home 3' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={homePicture3} alt='home 4' />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <ArrowDown height={160} />
      <section className='section2'>
        <div className='cards-container'>
          <div className='card' data-aos="flip-down" id='medicaux-card' onClick={() => window.location.href = "/services#classiques"} >
            <img src={stethoscopeIcon} alt='stethoscope' />
            <h3>NOS SERVICES
              MÉDICAUX</h3>
            <p>Médecine générale,
              Pédiatrie, Gynécologie-
              obstétrique, Chirurgie et
              Médecine interne</p>
          </div>
          <div className='card' data-aos="flip-down" id='specialises-card' onClick={() => window.location.href = "/services#specialise"}>
            <img src={bagIcon} alt='bag' />
            <h3>NOS SERVICES
              SPÉCIALISÉS</h3>
            <p>ORL, Ophtalmologie,
              Dentisterie, Kinésithérapie et la Médecine du travail</p>
          </div>
          <div className='card' data-aos="flip-down">
            <img src={ecographieIcon} alt='ecographie' />
            <h3>IMAGERIE MÉDICALE</h3>
            <p>Radiologie, Échographie</p>
          </div>
          <div className='card' data-aos="flip-down">
            <img src={labIcon} alt='lab' />
            <h3>LABORATOIRE</h3>
            <p>Prélèvement, Analyse</p>
          </div>
        </div>
      </section>
      <FactsCTA />
      <section className='section3'>
        <div className='section-cards-container'>
          <div className='cards-container'>
            <div className='card left-card' data-aos="fade-right">
              <div className='img-container'>
                <img src={consultationPicture} alt='consultation' id='consultpic' />
              </div>
              <h3>CONSULTATION</h3>
              <p>Contactez-nous par téléphone ou visitez l'une de nos réceptions pour planifier votre consultation. Nous sommes là pour vous accueillir et répondre à toutes vos questions.</p>
              <a href="#contact">Contactez-nous</a>
            </div>
            <div className='card middle-card' data-aos="fade-up">
              <img src={hospitalIcon} alt='bag' />
              <div className='content first'>
                <h3>SITE KASA-VUBU</h3>
                <p>Ouvert 24h/24 7j/7<br />
                  avec service de permanence</p>
              </div>
              <div className='content'>
                <h3>SITE BIAYI</h3>
                <p>Lundi - Vendredi | 8h00 - 17h00<br />
                  Samedi | 8h00 - 12h30</p>
              </div>
            </div>
            <div className='card right-card' data-aos="fade-left">
              <div className='img-container'>
                <img src={hospitalisation} alt='hospitalisation' />
              </div>
              <h3>HOSPITALISATION</h3>
              <p>Nos chambres pour court séjour sont disponibles pour un soin, un examen, une opération, un traitement ou une observation. Votre bien-être est notre priorité.</p>
            </div>
          </div>
        </div>

      </section>
    </div>
  )
}
