import React from 'react'

export default function DropDownMenuServices() {
    const handleMouseLeave = () => {
        document.getElementById('dropdown-menu-services').classList.remove('active');
    }

    return (
        <ul className='dropdown-menu' id="dropdown-menu-services" onMouseLeave={handleMouseLeave}>
            <li><a href='/services#classiques' id='classic-link'>Disciplines Classiques</a></li>
            <li><a href='/services#specialise' id='spec-link'>Disciplines Spécialisées</a></li>
        </ul>
    )
}
