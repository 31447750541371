import React from 'react'
import logo from '../images/logo-couleur.png'

export default function NotFound() {
  return (
    <div className='notfound'>
      <div className='container'>
        <img src={logo} alt='galien' />
        <h1>404</h1>
        <h2>Cette page est introuvable.</h2>
        <a href='/'>ACCUEIL</a>
      </div>
    </div>
  )
}
