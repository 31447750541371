import { createBrowserRouter } from "react-router-dom";
import Accueil from "./Accueil";
import Apropos from "./Apropos";
import Services from "./Services";
import NotFound from "./NotFound";
import MedecineTravail from "./MedecineTravail";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Accueil />
    },
    {
        path: '/apropos',
        element: <Apropos />
    },
    {
        path: '/services',
        element: <Services />
    },
    {
        path: '/medecine-travail',
        element: <MedecineTravail />
    },
    {
        path: '*',
        element: <NotFound />
    }

])

export default router;