import { React, useEffect, useState } from 'react'

function ArrowDown(props) {
    const height = props.height
    const [isVisible, setIsVisible] = useState(true);

    const listenToScroll = () => {
        let heightToHideFrom = height;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) {
            isVisible &&      // to limit setting state only the first time
                setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
        // eslint-disable-next-line
    }, [])
    return (
        <div className='arrows-container'>
            {isVisible ? <div className="arrows"></div> : ''}
        </div>
    )
}

export default ArrowDown