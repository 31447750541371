import React from 'react'

export default function DropDownMenuAboutUs() {

    const handleMouseLeave = () => {
        document.getElementById('dropdown-menu-apropos').classList.remove('active');

    }

    return (
        <ul className='dropdown-menu' id="dropdown-menu-apropos" onMouseLeave={handleMouseLeave}>
            <li><a href='/apropos#sommesnous' id='classic-link'>Qui sommes-nous?</a></li>
            <li><a href='/apropos#valeurs' id='spec-link'>Nos valeurs clefs</a></li>
            <li><a href='/apropos#mission' id='classic-link'>Notre mission</a></li>
            <li><a href='/apropos#vision' id='spec-link'>Notre vision</a></li>
        </ul>
    )
}
