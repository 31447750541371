import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars,faXmark } from '@fortawesome/free-solid-svg-icons'
import logo from '../images/logo-couleur.png'

function MobileNavbar() {
    const [showNav, setShowNavbar] = useState(false)
    const [currentPage, SetCurrentPage] = useState(window.location.pathname);

    useEffect(() => {
        SetCurrentPage(window.location.pathname);
    }, [])

    const handleShowNavbar = () => {
        if (showNav) {
            setShowNavbar(false)
        } else {
            setShowNavbar(true)
        }
    }

    return (
        <nav className='mobile-nav'>
            <a href='/' className='logo'>
                <img src={logo} alt="galien logo" />
            </a>
            <div className={`menu  ${showNav && 'active'}`}>
                {showNav ? <button onClick={handleShowNavbar}><FontAwesomeIcon icon={faXmark} /></button> : <button onClick={handleShowNavbar}><FontAwesomeIcon icon={faBars} /></button>}
                <ul>
                    <li><a href='/' className={currentPage === '/' ? 'current-page' : ''}>ACCUEIL</a></li>
                    <li><a href='/services' className={currentPage === '/services' ? 'current-page' : ''}>NOS SERVICES MÉDICAUX</a></li>
                    <li><a href='/medecine-travail' className={currentPage === '/medecine-travail' ? 'current-page' : ''}>MÉDECINE DU TRAVAIL</a></li>
                    <li><a href='/apropos' className={currentPage === '/apropos' ? 'current-page' : ''}>À PROPOS</a></li>
                    <li><a href="#contact" onClick={() => setShowNavbar(false)} className='last-item'>CONTACTEZ-NOUS</a></li>
                </ul>
            </div>
        </nav>
    )
}

export default MobileNavbar;