import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from './components/router';
import PlaceHolder from './components/PlaceHolder';
import Navbar from './components/Navbar';
import './styles/style.scss'
import Footer from './components/Footer';
import useWindowSize from './components/utils/useWindowSize';
import checkDevice from './components/utils/checkDevice';
import MobileNavbar from './components/MobileNavbar';
import DropDownMenuServices from './components/DropDownMenuServices';
import DropDownMenuAboutUs from './components/DropDownMenuAboutUs'

function App() {
    const size = useWindowSize();

    const isMobile = checkDevice(size, 1000)

    // work in progress
    const WIP = false;
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
        window.addEventListener('scroll', () => {
            document.getElementById('dropdown-menu-services').classList.remove('active');
            document.getElementById('dropdown-menu-apropos').classList.remove('active');
        });
    }, [])

    if (WIP) {
        return (
            <div className="App">
                <PlaceHolder />
                <Footer />
            </div>
        )
    } else {
        return (
            <div className="App">
                {isMobile ? <MobileNavbar /> : <Navbar />}
                <DropDownMenuServices />
                <DropDownMenuAboutUs />
                <RouterProvider router={router} />
                <Footer />
            </div>
        )
    }
}

export default App;
